import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../mixins';

export default {
    mixins: [myMixin],
    data: () => ({
        collections: [],
        params: {},
        message: '',
        error: false,
        loading: false,
        meta: {},
        titleForm: 'Nova Equivalência',
        itemDelete: {},
        model: {},
        show: false,
        showForm: false,
        text: '',
        deleting: false,
        headers: [
            { text: 'Equivalência', value: 'id', align: 'center'},
            { text: 'Código Disciplina', value: 'discipline_code', align: 'center'},
            { text: 'Disciplinas', value: 'disciplines', align: 'center'},
            { text: 'Carga Horária', value: 'workloads', align: 'center'},
            { text: 'Ações', value: 'actions', sortable: false, align: 'center', width: '90px'}
        ]
    }),
    methods: {
        ...mapActions('equivalence', ['ActionFindEquivalences', 'ActionDeleteEquivalence']),

        getData() {
            
            this.showForm = false;
            this.error = false;
            this.message = '';
            this.loading = true;
            const params = this.params;

            Object.assign(params, { with: 'equivalenceDisciplines.discipline,equivalenceDisciplines.curriculum' });

            this.ActionFindEquivalences(params)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteEquivalence({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                })
        }
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir a equivalência?";
            } else {
                this.show = false
                this.text = ''
            }
        },
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },

        model(item_new, item_old){
            
            if(item_new !== item_old && this.model.id)
            {
                this.titleForm = 'Editar Equivalência'
                this.showForm = true
            }else{
                this.titleForm = 'Nova Equivalência'
                this.showForm = false
            }           
        },

        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },
    }
}